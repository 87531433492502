/* eslint-disable max-len */
import React, { useCallback } from 'react';

import { Tag, Tooltip } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MdPeople } from 'react-icons/md';

import i18n from '~/translations';
import { BUYBOX_STATUS } from '~/values/enums';

import styles from './BuyboxTagDescription.module.scss';

const BUYBOX_UNKNOWN_MELI_REASONS = {
    non_trusted_seller: i18n.t('MELI_REASONS:NON_TRUSTED_SELLER'),
    reputation_below_threshold: i18n.t('MELI_REASONS:REPUTATION_BELOW_THRESHOLD'),
    winner_has_better_reputation: i18n.t('MELI_REASONS:WINNER_HAS_BETTER_REPUTATION'),
    manufacturing_time: i18n.t('MELI_REASONS:MANUFACTURING_TIME'),
    temporarily_winning_manufacturing_time: i18n.t('MELI_REASONS:TEMPORARILY_WINNING_MANUFACTURING_TIME'),
    temporarily_competing_manufacturing_time: i18n.t('MELI_REASONS:TEMPORARILY_COMPETING_MANUFACTURING_TIME'),
    temporarily_winning_best_reputation_available: i18n.t('MELI_REASONS:TEMPORARILY_WINNING_BEST_REPUTATION_AVAILABLE'),
    temporarily_competing_best_reputation_available: i18n.t('MELI_REASONS:TEMPORARILY_COMPETING_BEST_REPUTATION_AVAILABLE'),
    item_paused: i18n.t('MELI_REASONS:ITEM_PAUSED'),
    item_not_opted_in: i18n.t('MELI_REASONS:ITEM_NOT_OPTED_IN'),
    shipping_mode: i18n.t('MELI_REASONS:SHIPPING_MODE'),
};

const BuyboxTagDescription = ({ data, openListingBuyboxDialog }) => {
    const { t } = useTranslation('BUYBOX_TAG_DESCRIPTION');

    let { highlights } = data;

    const {
        marketplaceId, attributes,
    } = data;

    if (!highlights && data.buyboxStatus) {
        highlights = data.buyboxStatus;
    }

    const getIconColor = () => {
        switch (highlights) {
            case BUYBOX_STATUS.BUYBOX_WINNING:
                return styles.winner;
            case BUYBOX_STATUS.BUYBOX_CO_WINNING:
                return styles.coWinning;
            case BUYBOX_STATUS.BUYBOX_LOSING:
                return styles.loser;
            case BUYBOX_STATUS.BUYBOX_OUTSIDE_BUYBOX:
                return styles.outsideBuyBox;
            default:
                return styles.outsideBuyBox;
        }
    };

    const getTagClasses = () => {
        const classes = [styles.tag, getIconColor()];

        if (openListingBuyboxDialog) {
            classes.push(styles.click);
        }

        return classNames(classes);
    };

    const getTagText = () => {
        switch (highlights) {
            case BUYBOX_STATUS.BUYBOX_WINNING:
                return t('WINNING');
            case BUYBOX_STATUS.BUYBOX_CO_WINNING:
                return (
                    <>
                        {t('CO_WINNING')}
                    &nbsp;
                    &nbsp;
                        <MdPeople size={15} className={styles.groupIcon} />
                    </>
                );
            case BUYBOX_STATUS.BUYBOX_LOSING:
                return t('LOSING');
            case BUYBOX_STATUS.BUYBOX_NON_CATALOG:
                return t('NON_CATALOG');
            case BUYBOX_STATUS.BUYBOX_OUTSIDE_BUYBOX:
                return t('OUTSIDE_BUYBOX');
            default: return t('OUTSIDE_BUYBOX');
        }
    };

    const extractKeyFromAttributes = (attr = [], target = '') => {
        if (attr === null) {
            return null;
        }
        /* eslint-disable no-restricted-syntax */
        for (const attribute of attr) {
            if (attribute.key === target) {
                return attribute;
            }
        }
        return null;
    };

    const renderListedReasons = () => {
        let displayString = '';
        const reasonsObject = extractKeyFromAttributes(attributes, 'reason');

        if (reasonsObject === null) {
            return displayString;
        }

        const reasonsArray = reasonsObject.value.split(', ');

        /* eslint-disable guard-for-in */
        for (const reason of reasonsArray) {
            displayString += displayString + BUYBOX_UNKNOWN_MELI_REASONS[reason];
        }

        if (displayString === 'undefined') {
            return '';
        }

        return displayString;
    };

    const renderTooltip = () => {
        const tooltips = {
            1: {
                NOT_COMPETING: t('WINNER_NO_COMPETITOR_TOOLTIP'),
                OUTSIDE_BUYBOX: t('UNKNOWN_AMAZON_TOOLTIP'),
            },
            2: {
                NOT_COMPETING: t('WINNER_NO_COMPETITOR_TOOLTIP'),
                OUTSIDE_BUYBOX: data.attributes ? renderListedReasons() : t(''),
            },
        };

        return tooltips[marketplaceId]?.[highlights] || '';
    };

    const handleClick = useCallback(() => {
        openListingBuyboxDialog(data);
    }, [data, openListingBuyboxDialog]);

    return (
        <Tooltip title={renderTooltip()}>
            <Tag
                onClick={openListingBuyboxDialog ? handleClick : null}
                className={getTagClasses()}
            >
                {getTagText()}
            </Tag>
        </Tooltip>
    );
};

export default BuyboxTagDescription;
