/* eslint-disable react-perf/jsx-no-new-object-as-prop */
/* eslint-disable react-perf/jsx-no-new-array-as-prop */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-bind */

import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import {
    Divider,
    Form,
    Image,
    List,
    message,
    Modal,
    Radio,
    Space,
    Switch,
    Tag,
    InputNumber,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import AdModalHeader from '~/components/AdModalHeader/AdModalHeader';
import Button from '~/components/Button';
import Spinner from '~/components/Spinner';
import Table from '~/components/Table';
import { wrapDialog } from '~/helpers/dialog-helper';
import { wrapForm } from '~/helpers/form-helper';
import { maskMoney } from '~/helpers/mask-helper';
import { parseDecimal } from '~/helpers/parser-helper';
import useAxios from '~/hooks/use-axios';
import useDidMount from '~/hooks/use-did-mount';

import styles from './PricingDialog.module.scss';

const MODAL_STYLE = { top: 20 };
const { Column } = Table;

const PricingDialog = ({
    data, onCancel, onCancelPricingDialog,
    ...others
}) => {
    const axios = useAxios({
        baseURL: process.env.REACT_APP_URL_API_GO,
    });
    const { t } = useTranslation('PRICING_DIALOG');
    const adInfo = data[0];

    const [radioValueObjective, setRadioValueObjective] = useState(0);
    const [radioValueOverride, setRadioValueOverride] = useState(0);
    const [radioValuePermission, setRadioValuePermission] = useState(0);
    const [initialValues, setInitialValues] = useState({});
    const [massAction, setMassAction] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [valid, setValid] = useState(data.map(ad => {
        if (data.length === 1 && adInfo.ruleStatus !== 'none') {
            return {
                listingId: ad.id,
                valid: true,
            };
        }
        return {
            listingId: ad.id,
            valid: false,
        };
    }));
    const minMaxValues = useRef(data.map(ad => {
        const obj = {
            listingId: ad.id,
            sku: ad.sku,
            ruleStrategy: 'buybox_to_win',
            overrideAuthority: true,
            requiresConfirmation: true,
            minPrice: 0,
            maxPrice: 0,
        };

        if (data.length === 1 && adInfo.ruleStatus !== 'none') {
            obj.ruleStatus = adInfo.ruleStatus;
        }

        return obj;
    }, []));
    const ruleId = useRef(0);

    const RULE_LIST = {
        ruleStrategy: {
            title: t('OBJECTIVE_TITLE'),
            subtitle: t('OBJECTIVE_SUBTITLE'),
            items: [
                {
                    title: t('WIN_OBJECTIVE_TITLE'),
                    description: t('WIN_OBJECTIVE_DESCRIPTION'),
                    disabled: false,
                },
                {
                    title: t('WIN_EXCEPTION_OBJECTIVE_TITLE'),
                    description: t('WIN_EXCEPTION_OBJECTIVE_DESCRIPTION'),
                    disabled: true,
                },
            ],
        },
        overrideAuthority: {
            title: t('OVERRIDE_TITLE'),
            subtitle: t('OVERRIDE_SUBTITLE'),
            items: [
                {
                    title: t('REGAIN_OVERRIDE_TITLE'),
                    description: t('REGAIN_OVERRIDE_DESCRIPTION'),
                    disabled: false,
                },
                {
                    title: t('LOSE_OVERRIDE_TITLE'),
                    description: t('LOSE_OVERRIDE_DESCRIPTION'),
                    disabled: false,
                },
            ],
        },
        requiresConfirmation: {
            title: t('PERMISSION_TITLE'),
            subtitle: t('PERMISSION_SUBTITLE'),
            items: [
                {
                    title: t('ASK_PERMISSION_TITLE'),
                    description: t('ASK_PERMISSION_DESCRIPTION'),
                    disabled: false,
                },
                {
                    title: t('DONT_ASK_PERMISSION_TITLE'),
                    description: t('DONT_ASK_PERMISSION_DESCRIPTION'),
                    disabled: false,
                },
            ],
        },
    };

    const requestDataSource = useCallback(async () => {
        const listingId = adInfo.listingId || adInfo.id;
        try {
            const response = await axios.get(`v1/rules/${listingId}`);
            return response;
        } catch (e) {
            message.error('Connection Error: ', e);
            return {};
        }
    }, [axios]);

    useDidMount(() => {
        if (data.length > 1) {
            setMassAction(true);
        }
    }, []);

    useEffect(async () => {
        if (data.length === 1 && adInfo.ruleStatus !== 'none') {
            const response = await requestDataSource();
            setInitialValues(response.data);
            minMaxValues.current[0].minPrice = response.data?.minPrice;
            minMaxValues.current[0].maxPrice = response.data?.maxPrice;
            setRadioValueObjective(response.data?.ruleStrategy === 'buybox_to_win' ? 0 : 1);
            setRadioValueOverride(response.data?.overrideAuthority ? 0 : 1);
            setRadioValuePermission(response.data?.requiresConfirmation ? 0 : 1);
            ruleId.current = response.data?.id;
        }
        setIsLoading(false);
    }, []);

    const renderMarketplaceName = useCallback(marketplaceId => {
        switch (marketplaceId) {
            case 1:
                return t('AMAZON');
            case 2:
                return t('MERCADO_LIVRE');
            default:
                return '';
        }
    }, [t]);

    const renderMoneyColumn = useCallback((value, adData) => {
        const money = maskMoney(value, adData);
        return money;
    }, []);

    const onSwitchChange = value => {
        if (value) {
            minMaxValues.current[0].ruleStatus = 'enabled';
            return;
        }
        minMaxValues.current[0].ruleStatus = 'disabled';
    };

    const renderStatus = useCallback(status => {
        if (!massAction) {
            const newValue = minMaxValues.current[0];
            newValue.ruleStatus = status;
            minMaxValues.current = [newValue];
        }
        return (
            <div className={styles.ruleStatusContainer}>
                <Switch
                    checkedChildren={t('STATUS_ENABLED')}
                    unCheckedChildren={status === 'paused' ? t('STATUS_PAUSED') : t('STATUS_DISABLED')}
                    onChange={value => onSwitchChange(value)}
                    size="default"
                    defaultChecked={status === 'enabled'}
                />
            </div>
        );
    }, []);

    const renderHeader = useMemo(() => {
        return (
            <div className={styles.pricingHeaderContainer}>
                <AdModalHeader listingId={adInfo.listingId || adInfo.id} modal="pricing" />
                {adInfo.ruleStatus === 'none' ? null : (
                    <div className={styles.switchContainer}>
                        <div className={styles.infoLineTitle}>{t('RULE_STATUS')}</div>
                        {renderStatus(adInfo.ruleStatus)}
                    </div>
                )}
            </div>
        );
    }, [data, initialValues]);

    const onRadioChange = (e, radioGroup) => {
        let newValues = [];
        switch (radioGroup) {
            case 0:
                setRadioValueObjective(e.target.value);
                newValues = minMaxValues.current.map(ad => {
                    if (e.target.value === 0) {
                        ad.ruleStrategy = 'buybox_to_win';
                    }
                    ad.ruleStrategy = 'buybox_to_win_exceptions';
                    return ad;
                });
                break;
            case 1:
                setRadioValueOverride(e.target.value);
                newValues = minMaxValues.current.map(ad => {
                    if (e.target.value === 0) {
                        ad.overrideAuthority = true;
                        return ad;
                    }
                    ad.overrideAuthority = false;
                    return ad;
                });
                break;
            case 2:
                setRadioValuePermission(e.target.value);
                newValues = minMaxValues.current.map(ad => {
                    if (e.target.value === 0) {
                        ad.requiresConfirmation = true;
                        return ad;
                    }
                    ad.requiresConfirmation = false;
                    return ad;
                });
                break;
            default:
                break;
        }
        minMaxValues.current = newValues;
    };

    const renderRules = useMemo(() => {
        return (
            <div className={styles.configurationsContainer}>
                {Object.keys(RULE_LIST).map(rule => {
                    let radioGroup = 0;
                    let radioValue = 0;

                    switch (rule) {
                        case 'ruleStrategy':
                            radioGroup = 0;
                            radioValue = radioValueObjective;
                            break;
                        case 'overrideAuthority':
                            radioGroup = 1;
                            radioValue = radioValueOverride;
                            break;
                        case 'requiresConfirmation':
                            radioGroup = 2;
                            radioValue = radioValuePermission;
                            break;
                        default:
                            return null;
                    }

                    return (
                        <div>
                            <Title level={4} type="secondary" className={styles.listTitle}>
                                {RULE_LIST[rule].title}
                            </Title>

                            {RULE_LIST[rule].subtitle}

                            <Radio.Group
                                className={styles.group}
                                onChange={e => onRadioChange(e, radioGroup)}
                                value={radioValue}
                            >
                                {RULE_LIST[rule].items.map((item, index) => {
                                    return (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={(
                                                    <Radio value={index} disabled={item.disabled} />
                                                )}
                                                title={item.disabled
                                                    ? (
                                                        <div className={styles.comingSoonTitle}>
                                                            {item.title}
                                                            &nbsp;
                                                            <Tag
                                                                className={classNames([styles.tag, styles.comingSoon])}
                                                            >
                                                                {t('COMING_SOON')}
                                                            </Tag>
                                                        </div>
                                                    ) : item.title}
                                                description={item.description}
                                            />
                                        </List.Item>
                                    );
                                })}
                            </Radio.Group>
                        </div>
                    );
                }, [radioValueObjective, radioValueOverride, initialValues, radioValuePermission])}
            </div>
        );
    }, [radioValueObjective, radioValueOverride, initialValues, radioValuePermission]);

    const renderImageColumn = useCallback(value => {
        return (
            <div className={styles.productImageContainer}>
                <Image
                    width={60}
                    height={60}
                    src={value}
                    preview={false}
                    fallback={require('~/assets/no-image.png').default}
                />
            </div>
        );
    }, []);

    const onValuesChange = useCallback((value, adData, form) => {
        let newValidInputs = [...valid];

        const newValues = minMaxValues.current.map(ad => {
            if (ad.listingId === adData.id) {
                ad.minPrice = form.getFieldValue(`minPrice:${adData.id}`);
                ad.maxPrice = form.getFieldValue(`maxPrice:${adData.id}`);
            }
            return ad;
        });
        minMaxValues.current = newValues;

        newValidInputs = valid.map(ad => {
            if (ad.listingId === adData.id) {
                ad.valid = false;
            }
            return ad;
        });

        form.validateFields([`maxPrice:${adData.id}`]).then(result => {
            if (result) {
                newValidInputs = valid.map(ad => {
                    if (ad.listingId === adData.id) {
                        if (form.getFieldValue(`minPrice:${adData.id}`) !== 0) {
                            ad.valid = true;
                        }
                    }
                    return ad;
                });
            }
        }).catch(err => null);

        setValid(newValidInputs);
        return null;
    }, [minMaxValues]);

    const RenderPriceRangeInputFields = adData => {
        const [form] = Form.useForm();
        const [helpMessage, setHelpMessage] = useState({ minPrice: '', maxPrice: '' });

        const checkMinMaxPricing = () => {
            const actualMinPrice = form.getFieldValue(`minPrice:${adData.id}`);
            const actualMaxPrice = form.getFieldValue(`maxPrice:${adData.id}`);

            const calculateDiffPercentage = price => ((Math.abs(price - adData.price) / adData.price) * 100);

            const getMessage = (price, diffPercentage) => t('VALIDATIONS:VALIDATE_MIN_MAX_%')
                .replace('XX', diffPercentage.toFixed(0))
                .replace('YY', price > adData.price ? '+' : '-');

            const minPriceDiffPercentage = calculateDiffPercentage(actualMinPrice);
            const maxPriceDiffPercentage = calculateDiffPercentage(actualMaxPrice);

            const newHelpMessage = {
                minPrice: minPriceDiffPercentage > 1 ? getMessage(actualMinPrice, minPriceDiffPercentage) : '',
                maxPrice: (actualMaxPrice <= actualMinPrice) ? null
                    : (maxPriceDiffPercentage > 1 ? getMessage(actualMaxPrice, maxPriceDiffPercentage) : ''),
            };

            setHelpMessage(newHelpMessage);
        };

        let minPrice = 0;
        let maxPrice = 0;

        if (initialValues && Object.keys(initialValues).length > 0) {
            minPrice = initialValues.minPrice;
            maxPrice = initialValues.maxPrice;
        }

        return (
            <Form
                form={form}
                name={adData.id}
                preserve={false}
                validateTrigger="onBlur"
                onValuesChange={values => {
                    onValuesChange(values, adData, form);
                    checkMinMaxPricing();
                }}
                className={styles.inputFieldsCenter}
                initialValues={{ [`minPrice:${adData.id}`]: minPrice, [`maxPrice:${adData.id}`]: maxPrice }}
            >
                <Form.Item
                    name={`minPrice:${adData.id}`}
                    label={t('MINIMUM_PRICE_COLUMN')}
                    className={styles.inputWithNoMargin}
                    required
                    rules={[
                        { required: true, message: t('VALIDATIONS:VALIDATE_REQUIRED') },
                    ]}
                    help={helpMessage.minPrice}
                >
                    <InputNumber
                        className={styles.inputField}
                        size="large"
                        type="text"
                        parser={parseDecimal}
                        formatter={value => renderMoneyColumn(parseFloat(value), adData)}
                    />
                </Form.Item>
                &nbsp;&nbsp;&nbsp;
                <Form.Item
                    name={`maxPrice:${adData.id}`}
                    label={t('MAXIMUM_PRICE_COLUMN')}
                    className={styles.inputWithNoMargin}
                    required
                    rules={[
                        { required: true, message: t('VALIDATIONS:VALIDATE_REQUIRED') },
                        forms => ({
                            type: 'number',
                            min: forms.getFieldValue(`minPrice:${adData.id}`) + 0.001,
                            message: t('VALIDATIONS:VALIDATE_LESSER_THAN'),
                        }),
                    ]}
                    help={helpMessage.maxPrice}
                >
                    <InputNumber
                        className={styles.inputField}
                        size="large"
                        type="text"
                        parser={parseDecimal}
                        formatter={value => renderMoneyColumn(parseFloat(value), adData)}
                    />
                </Form.Item>
            </Form>
        );
    };

    const renderListing = useMemo(() => {
        return (
            <Table
                {...others}
                dataSource={data}
                scroll={{ y: 450 }}
                pagination={false}
            >
                <Column
                    title={t('IMAGE_COLUMN')}
                    key="imageUrl"
                    dataIndex="imageUrl"
                    render={renderImageColumn}
                    align="center"
                    width={80}
                    fixed="left"
                />
                <Column
                    title={t('AD_COLUMN')}
                    key="title"
                    dataIndex="title"
                    dataSorter="title"
                />
                <Column
                    title={t('MARKETPLACE_COLUMN')}
                    key="marketplaceId"
                    dataIndex="marketplaceId"
                    render={renderMarketplaceName}
                    align="center"
                    width={120}
                />
                <Column
                    title={t('SKU_COLUMN')}
                    key="sku"
                    dataIndex="sku"
                    dataSorter="sku"
                    align="center"
                    width={120}
                />
                <Column
                    title={t('PRICE_COLUMN')}
                    key="price"
                    dataIndex="price"
                    dataSorter="price"
                    render={renderMoneyColumn}
                    align="center"
                    width={90}
                />
                <Column
                    title={t('SHIPPING_COLUMN')}
                    key="shippingPrice"
                    dataIndex="shippingPrice"
                    dataSorter="shippingPrice"
                    render={renderMoneyColumn}
                    align="center"
                    width={95}
                />
                <Column
                    title={t('PRICE_RANGE_COLUMN')}
                    render={RenderPriceRangeInputFields}
                    align="center"
                    width={330}
                />
            </Table>
        );
    }, [t, data, initialValues]);

    const renderRulesSection = useMemo(() => {
        return (
            <div>
                <Space
                    direction="vertical"
                    className={styles.space}
                    size={10}
                >
                    <Divider>
                        <Title level={3} type="secondary" className={styles.listTitle}>
                            {t('RULES_SECTION_TITLE')}
                        </Title>
                    </Divider>

                    <div className={styles.configurationsContainer}>
                        {renderRules}
                    </div>
                </Space>
            </div>
        );
    }, [renderRules, initialValues]);

    const renderPricingSection = useMemo(() => {
        return (
            <div>
                <Space
                    direction="vertical"
                    className={styles.space}
                    size={40}
                >
                    <div className={styles.center}>
                        <Divider>
                            <Title level={3} type="secondary" className={styles.listTitle}>
                                {t('PRICING_SECTION_TITLE')}
                            </Title>
                        </Divider>
                        {t('PRICING_SECTION_SUBTITLE')}
                    </div>

                    {massAction ? renderListing : (
                        <RenderPriceRangeInputFields
                            {...adInfo}
                        />
                    )}
                </Space>
            </div>
        );
    }, [renderListing, massAction, adInfo, t]);

    const cancel = () => {
        onCancelPricingDialog();
        onCancel();
    };

    const onConfirmClick = useCallback(async () => {
        const validConfirm = valid.filter(ad => !ad.valid).length === 0;

        if (validConfirm) {
            try {
                if (!massAction && adInfo.ruleStatus !== 'none') {

                    await axios.put(`v1/rules/${ruleId.current}`, minMaxValues.current[0]);
                    if (minMaxValues.current[0].ruleStatus !== initialValues?.ruleStatus) {
                        await axios.put(`v1/rules/${ruleId.current}/status`, {
                            listingId: adInfo.id,
                            ruleStatus: minMaxValues.current[0].ruleStatus,
                        });
                    }
                } else {
                    await axios.post('v1/rules', {
                        data: minMaxValues.current,
                    });
                }
                message.success(t('SUCCESS_ON_CREATE_RULE'));
            } catch (err) {
                message.error(t('ERROR_ON_CREATE_RULE'));
            }
            cancel();
            return;
        }

        message.error(t('CORRECT_INVALID_ADS'));
    }, [initialValues, minMaxValues, t]);

    const renderSubmitButtons = useMemo(() => {
        return (
            <div className={styles.buttonContainer}>
                <Button className={styles.submitButtons} onClick={onConfirmClick}>
                    {t('CONFIRM')}
                </Button>
                <Button type="default" className={[styles.submitButtons, styles.cancelButton]} onClick={cancel}>
                    {t('CANCEL')}
                </Button>
            </div>
        );
    }, []);

    return (
        <Modal
            {...others}
            title={t('PRICING_CONFIGURATION_TITLE')}
            closable
            destroyOnClose
            width={1300}
            style={MODAL_STYLE}
            onCancel={cancel}
            footer={renderSubmitButtons}
        >
            <Spinner
                spinning={isLoading}
            >
                <Space
                    direction="vertical"
                    className={styles.space}
                    size={25}
                >
                    {massAction ? null : renderHeader}
                    {renderRulesSection}
                    {renderPricingSection}
                </Space>
            </Spinner>
        </Modal>
    );
};

export default wrapDialog(wrapForm(PricingDialog));
