import React from 'react';

import { Image, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon';
import { STATUS_LISTING } from '~/domains/listing/AdTag';
import { MARKETPLACES_BY_ID } from '~/values/enums';

import makeURL from '../MakeUrl';
import styles from './AdDescriptionColumn.module.scss';

function AdDescriptionColumn({ adData, competitor = false }) {
    const { t } = useTranslation('AD_DESCRIPTION_COMPONENT');

    const {
        imageUrl,
        imageUri,
        title,
        accountName,
        marketplaceId,
        sku,
        skuMarketplace,
        competitorProductTitle,
        competitorImageUri,
        status,
    } = adData;

    const image = imageUrl || imageUri;
    const marketplaceName = MARKETPLACES_BY_ID.getItemByKey(marketplaceId)?.label || null;
    const statusName = STATUS_LISTING.find(statusListing => statusListing.key === status);

    const marketplaceDetails = `${marketplaceName} ${accountName
        ? `| ${accountName}` : ''} ${statusName?.label ? `| ${statusName?.label}` : ''}`;

    const renderImage = () => {
        return (
            <div className={styles.productImageContainer}>
                <Image
                    width={60}
                    height={60}
                    src={competitor ? competitorImageUri : image}
                    preview={false}
                    fallback={require('~/assets/no-image.png').default}
                />
            </div>
        );
    };

    const renderTooltip = translation => {
        const conditionalHref = competitor ? adData.competitorLink : makeURL(title, adData);

        return (
            <Tooltip title={translation}>
                <a href={conditionalHref} target="_blank" rel="noreferrer">
                    <i className={styles.linkIcon}>
                        <Icon family="MaterialIcons" name="open_in_new" size={15} />
                    </i>
                </a>
            </Tooltip>
        );
    };

    const renderCompetitorAdDescription = productTitle => {
        return (
            <>
                {productTitle} &nbsp;
                {renderTooltip(t('TOOLTIP_GO_TO_COMPETITOR_PRODUCT'))}
            </>
        );
    };

    return (
        <div className={styles.adDescriptionColumn}>
            {renderImage()}
            {competitor ? (
                <div className={styles.competitorTitle}>
                    {renderCompetitorAdDescription(competitorProductTitle)}
                </div>
            ) : (
                <div className={styles.adDetails}>
                    <h6 className={styles.marketplaceDetails}>
                        {marketplaceDetails.toLowerCase()}
                    </h6>
                    <h6 className={styles.adTitle}>{title.toLowerCase()}</h6>
                    <span className={styles.accountDetails}>
                        <p>
                            {t('MARKETPLACE_SKU')}: {skuMarketplace}
                        </p>
                        |
                        <p>
                            {t('SKU')}: {sku}
                        </p>
                        {renderTooltip(t('TOOLTIP_GO_TO_MARKETPLACE_LISTING'))}
                    </span>
                </div>
            )}
        </div>
    );
}

export default AdDescriptionColumn;
