import {
    maskMoney, maskNumberWithoutDecimal, maskPercentage, maskRoas,
} from '~/helpers/mask-helper';
import i18n from '~/translations';

class EnumArray extends Array {

    /**
     *
     * @param {string} key
     * @returns {string}
     */
    getLabelByKey(key) {
        const foundItem = this.getItemByKey(key);
        return foundItem?.label;
    }

    /**
     *
     * @param {string} key
     * @returns {object}
     */
    getItemByKey(key) {
        return this.find(item => item.key === key);
    }

}

export const CAMPAGIN_REPORT_TYPE_DESCRIPTIONS = {
    KEYWORD: i18n.t('ENUMS:KEYWORD'),
    PRODUCT: i18n.t('ENUMS:CAMPAIGN_REPORT_TYPE_DESCRIPTIONS_PRODUCT'),
};

export const CAMPAIGN_REPORT_TYPE = new EnumArray(
    {
        key: 'PRODUCT',
        label: i18n.t('ENUMS:CAMPAIGN_REPORT_TYPE_DESCRIPTIONS_PRODUCT'),
    },
    {
        key: 'KEYWORD',
        label: i18n.t('ENUMS:CAMPAIGN_REPORT_TYPE_KEYWORD'),
    },
);

export const CAMPAGIN_MATCH_TYPE = new EnumArray(
    {
        key: 'EXACT',
        label: i18n.t('ENUMS:EXACT'),
    },
    {
        key: 'PHRASE',
        label: i18n.t('ENUMS:PHRASE'),
    },
    {
        key: 'BROAD',
        label: i18n.t('ENUMS:BROAD'),
    },
);

export const SITUATIONS = new EnumArray(
    {
        key: 1,
        label: i18n.t('ENUMS:ACTIVE'),
    },
    {
        key: 0,
        label: i18n.t('ENUMS:INACTIVE'),
    },
);

export const SITUATIONS_EXTRACT = new EnumArray(
    {
        key: 'E',
        label: i18n.t('ENUMS:ENTRY'),
    },
    {
        key: 'S',
        label: i18n.t('ENUMS:EXIT'),
    },
);

export const SITUATIONS_AUDIT = new EnumArray(
    {
        key: 'C',
        label: i18n.t('ENUMS:CONCLUDED'),
    },
    {
        key: 'A',
        label: i18n.t('ENUMS:OPEN'),
    },
);

export const SITUATIONS_REASON = new EnumArray(
    {
        key: 'RE',
        label: i18n.t('ENUMS:REIMBURSEMENT'),
    },
    {
        key: 'ES',
        label: i18n.t('ENUMS:REVERSAL'),
    },
    {
        key: 'RC',
        label: i18n.t('ENUMS:CREDIT_TOP_UP'),
    },
    {
        key: 'CE',
        label: i18n.t('ENUMS:SHIPPING_COST'),
    },
    {
        key: 'RA',
        label: i18n.t('ENUMS:AUDIT_REIMBURSEMENT'),
    },
    {
        key: 'DA',
        label: i18n.t('ENUMS:AUDIT_DISCOUNT'),
    },
);

export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5mb

export const ACCEPT_FILES = [
    'application/xls',
    'application/xlsx',
    'application/csv',
];

export const SEGMENTATIONS_KEYS = {
    AUTO: 'AUTO',
    MANUAL: 'MANUAL',
    CONVERTERA: 'CONVERTERA',
};

export const MARKETPLACES_ID = {
    AMAZON: 1,
    MERCADO_LIVRE: 2,
};

export const MARKETPLACES_KEYS = {
    AMAZON: 'amazon',
    MERCADO_LIVRE: 'meli',
};

export const MARKETPLACES_COLORS = {
    AMAZON: '#3b5999',
    SHOPEE: '#FF6600',
};

export const MARKETPLACES_NAMES = {
    AMAZON: i18n.t('ENUMS:AMAZON'),
};

export const MARKETPLACES_BY_ID = new EnumArray(
    {
        key: 1,
        label: i18n.t('ENUMS:AMAZON'),
    },
    {
        key: 2,
        label: i18n.t('ENUMS:MERCADO_LIVRE'),
    },
    // {
    //     key: 3,
    //     label: i18n.t('ENUMS:MAGAZINE_LUIZA'),
    // },
    // {
    //     key: 4,
    //     label: i18n.t('ENUMS:SHOPEE'),
    // },
);

export const SEGMENTATIONS = new EnumArray(
    {
        key: SEGMENTATIONS_KEYS.AUTO,
        label: i18n.t('ENUMS:AUTOMATIC'),
        item: {
            description: i18n.t('ENUMS:SEGMENTATIONS_TARGET_SIMILAR_KEYWORDS_PRODUCTS'),
        },
    },
    {
        key: SEGMENTATIONS_KEYS.CONVERTERA,
        label: i18n.t('ENUMS:SEGMENTATIONS_CONVERTERA'),
        item: {
            description: i18n.t('ENUMS:SEGMENTATIONS_TARGET_YOUR_ADS_TO_SPECIFIC_AUDIENCES'),
        },
    },
    {
        key: SEGMENTATIONS_KEYS.MANUAL,
        label: i18n.t('ENUMS:SEGMENTATIONS_MANUAL'),
        item: {
            description: i18n.t('ENUMS:SEGMENTATIONS_GET_FULL_CONTROL_OVER_YOUR_AUDIENCE_SETTINGS'),
        },
    },
);

export const SEGMENTATIONS_TYPES_KEYS = {
    KEYWORD: 'KEYWORD',
    PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
};

export const SEGMENTATION_TYPES = new EnumArray(
    {
        key: SEGMENTATIONS_TYPES_KEYS.KEYWORD,
        label: i18n.t('ENUMS:SEGMENTATION_TYPES_KEYWORDS'),
        item: {
            description: i18n.t('ENUMS:SEGMENTATION_TYPES_CHOOSE_KEYWORDS_HELP_YOUR_PRODUCTS'),
            tip: i18n.t('ENUMS:SEGMENTATION_TYPES_KEYWORD_TARGETING'),
        },
    },
    {
        key: SEGMENTATIONS_TYPES_KEYS.PRODUCT_CATEGORY,
        label: i18n.t('ENUMS:SEGMENTATION_TYPES_PRODUCTS_OR_CATEGORIES'),
        item: {
            description: i18n.t('ENUMS:SEGMENTATION_CHOOSE_PRODUCTS_CATEGORIES'),
            tip: i18n.t('ENUMS:SEGMENTATION_PRODUCT_TARGETING_TO_CHOOSE_SPECIFIC_PRODUCTS'),
        },
    },
);

export const BID_TYPES = new EnumArray(
    {
        key: 'DEFAULT',
        label: i18n.t('ENUMS:BID_TYPES_DEFAULT'),
        item: {
            description: i18n.t('ENUMS:BID_TYPES_DEFAULT_BID_TO_ALL_CLICK'),
        },
    },
    {
        key: 'GROUP',
        label: i18n.t('ENUMS:BID_TYPES_SEGMENTATION_GROUP'),
        item: {
            description: i18n.t('ENUMS:BID_TYPES_SEGMENTATION_GROUP_USE_VARIOUS_STRATEGIES'),
        },
    },
);

export const BID_STRATEGIES = new EnumArray(
    {
        key: 'DYNAMIC_DOWN_ONLY',
        label: i18n.t('ENUMS:BID_STRATEGIES'),
        item: {
            description: i18n.t('ENUMS:BID_STRATEGIES_LOWER_REAL_TIME_BIDS'),
        },
    },
    {
        key: 'DYNAMIC_UP_AND_DOWN',
        label: i18n.t('ENUMS:BID_STRATEGIES_UP_AND_DOWN'),
        item: {
            description: i18n.t('ENUMS:BID_STRATEGIES_INCREASE_YOUR_BIDS'),
        },
    },
    {
        key: 'FIXED',
        label: i18n.t('ENUMS:BID_STRATEGIES_FIXED'),
        item: {
            description: i18n.t('ENUMS:BID_STRATEGIES_USE_EXACT_BID_ANY_MANUAL'),
        },
    },
);

export const BID_STRATEGIES_PLACEMENTS = new EnumArray(
    {
        key: 'TOP_SEARCH',
        label: i18n.t('ENUMS:BID_STRATEGIES_PLACEMENTS'),
        simpleLabel: i18n.t('ENUMS:BID_STRATEGIES_PLACEMENTS_TOP_OF_SEARCH'),
    },
    {
        key: 'PRODUCT_PAGE',
        label: i18n.t('ENUMS:BID_STRATEGIES_PLACEMENTS_PRODUCTS'),
        simpleLabel: i18n.t('ENUMS:BID_STRATEGIES_PLACEMENTS_PRODUCTS'),
    },
);

export const BID_SEGMENTATION_GROUPS = new EnumArray(
    {
        key: 'QUERY_HIGH_REL_MATCHES',
        label: i18n.t('ENUMS:BID_SEGMENTATION_GROUPS_IDENTICAL'),
        item: {
            description: i18n.t('ENUMS:BID_SEGMENTATION_GROUPS_SHOW_AD_TO_USING_SEARCH_TERMS_RELATED'),
            objectKey: 'closeMatch',
        },
    },
    {
        key: 'QUERY_BROAD_REL_MATCHES',
        label: i18n.t('ENUMS:BID_SEGMENTATION_GROUPS_SIMILAR'),
        item: {
            description: i18n.t('ENUMS:BID_SEGMENTATION_GROUPS_SIMILAR_SHOW_AD_TO_USING_SEARCH_TERMS_LOOSELY_RELATED'),
            objectKey: 'looseMatch',
        },
    },
    {
        key: 'ASIN_SUBSTITUTE_RELATED',
        label: i18n.t('ENUMS:BID_SEGMENTATION_GROUPS_ALTERNATIVES'),
        item: {
            description: i18n.t('ENUMS:BID_SEGMENTATION_GROUPS_SHOW_AD_TO_USE_PRODUCT_DETAIL_PAGE_SIMILAR'),
            objectKey: 'substitutes',
        },
    },
);

export const RECOMMENDATION_TAGS = {
    READY_TO_INVEST: 'READY_TO_INVEST',
    CHANGE_STRATEGY: 'CHANGE_STRATEGY',
    LOSING_BUYBOX: 'LOSING_BUYBOX',
    IMPROVE_HEALTH: 'IMPROVE_HEALTH',
    NOT_ATTRACTIVE: 'NOT_ATTRACTIVE',
    HIGH_CPC: 'HIGH_CPC',
    LOW_CONVERSION: 'LOW_CONVERSION',
    HIGH_CONSUMPTION: 'HIGH_CONSUMPTION',
    DOING_WELL: 'DOING_WELL',
    ACOS_GREATER_OBJECTIVE: 'ACOS_GREATER_OBJECTIVE',
};

export const CAMPAIGN_STRATEGY = {
    RENTABILITY: 'RENTABILITY',
    PROFITABILITY: 'PROFITABILITY',
    INCREASE: 'INCREASE',
    VISIBILITY: 'VISIBILITY',
};

export const PRICE_MONITORING_STATUS = new EnumArray(
    {
        key: 'WINNING',
        label: i18n.t('ENUMS:PRICE_MONITORING_STATUS_WINNING'),
        item: {
            description: i18n.t('ENUMS:PRICE_MONITORING_STATUS_WINNING'),
            tagColor: 'success',
        },
    },
    {
        key: 'LOSING',
        label: i18n.t('ENUMS:PRICE_MONITORING_STATUS_LOSING'),
        item: {
            description: i18n.t('ENUMS:PRICE_MONITORING_STATUS_LOSING'),
            tagColor: 'processing',
        },
    },
);

export const CAMPAIGN_STATUS = new EnumArray(
    {
        key: 'ENABLED',
        label: i18n.t('ENUMS:CAMPAIGN_STATUS_ACTIVE'),
        item: {
            description: i18n.t('ENUMS:CAMPAIGN_STATUS_ACTIVE_CAMPAIGN'),
            tagColor: 'success',
        },
    },
    {
        key: 'PAUSED',
        label: i18n.t('ENUMS:CAMPAIGN_STATUS_PAUSED'),
        item: {
            description: i18n.t('ENUMS:CAMPAIGN_STATUS_CAMPAIGN_PAUSED'),
            tagColor: 'processing',
        },
    },
    {
        key: 'ARCHIVED',
        label: i18n.t('ENUMS:CAMPAIGN_STATUS_ARCHIVED'),
        item: {
            description: i18n.t('ENUMS:CAMPAIGN_STATUS_CAMPAIGN_ARCHIVED'),
            tagColor: 'default',
        },
    },
    {
        key: 'UNDEFINED',
        label: i18n.t('ENUMS:CAMPAIGN_STATUS_UNDEFINED'),
        item: {
            description: i18n.t('ENUMS:CAMPAIGN_STATUS_CAMPAIGN_UNDEFINED'),
            tagColor: 'processing',
        },
    },
);

export const CAMPAIGN_STATES = new EnumArray(
    {
        key: 'ENABLED',
        label: i18n.t('ENUMS:CAMPAIGN_STATUS_ACTIVE'),
        item: {
            description: i18n.t('ENUMS:CAMPAIGN_STATES_CAMPAIGN_ACTIVE'),
            tagColor: 'success',
        },
    },
    {
        key: 'PENDIND_START_DATE',
        label: i18n.t('ENUMS:CAMPAIGN_STATES_WAITING_FOR_START_DATE'),
        item: {
            description: i18n.t('ENUMS:CAMPAIGN_STATES_IS_REGULAR_BUT_AWAITING'),
            tagColor: 'processing',
        },
    },
    {
        key: 'ARCHIVED',
        label: i18n.t('ENUMS:CAMPAIGN_STATUS_ARCHIVED'),
        item: {
            description: i18n.t('ENUMS:CAMPAIGN_STATUS_CAMPAIGN_ARCHIVED'),
            tagColor: 'default',
        },
    },
    {
        key: 'PAUSED',
        label: i18n.t('ENUMS:CAMPAIGN_STATUS_PAUSED'),
        item: {
            description: i18n.t('ENUMS:CAMPAIGN_STATUS_CAMPAIGN_PAUSED'),
            tagColor: 'processing',
        },
    },
    {
        key: 'INCOMPLETE',
        label: i18n.t('ENUMS:CAMPAIGN_STATES_INCOMPLETE'),
        item: {
            description: i18n.t('ENUMS:CAMPAIGN_STATES_CAMPAIGN_INCOMPLETE'),
            tagColor: 'warning',
        },
    },
);

export const RULES_STATUS = new EnumArray(
    {
        key: 'ENABLED',
        label: i18n.t('ENUMS:RULES_STATUS_ACTIVE'),
        item: {
            description: i18n.t('ENUMS:RULES_STATUS_ACTIVE_RULES'),
            tagColor: 'success',
        },
    },
    {
        key: 'PAUSED',
        label: i18n.t('ENUMS:RULES_STATUS_PAUSED'),
        item: {
            description: i18n.t('ENUMS:RULES_STATUS_RULES_PAUSED'),
            tagColor: 'processing',
        },
    },
    {
        key: 'ARCHIVED',
        label: i18n.t('ENUMS:RULES_STATUS_ARCHIVED'),
        item: {
            description: i18n.t('ENUMS:RULES_STATUS_RULES_ARCHIVED'),
            tagColor: 'default',
        },
    },
    {
        key: 'UNDEFINED',
        label: i18n.t('ENUMS:RULES_STATUS_UNDEFINED'),
        item: {
            description: i18n.t('ENUMS:RULES_STATUS_RULES_UNDEFINED'),
            tagColor: 'processing',
        },
    },
);

export const ADS_HEALTH = new EnumArray(
    {
        key: 'DOWN',
        label: i18n.t('ENUMS:ADS_HEALTH_LOW'),
        item: {
            start: 0,
            end: 50,
            color: 'error',
            hexColor: '#ff4d4f',
        },
    },
    {
        key: 'NEUTRAL',
        label: i18n.t('ENUMS:ADS_HEALTH_AVERAGE'),
        item: {
            start: 51,
            end: 79,
            color: 'warning',
            hexColor: '#faad14',
        },
    },
    {
        key: 'UP',
        label: i18n.t('ENUMS:ADS_HEALTH_HIGH'),
        item: {
            start: 80,
            end: 100,
            color: 'success',
            hexColor: '#52c41a',
        },
    },
);

export const DEFAULT_PAGE_SIZE = 15;

export const DEFAULT_TABLE_PAGINATION = {
    page: 1,
    total: 0,
    count: 0,
    limit: DEFAULT_PAGE_SIZE,
};

export const SHIPPING_TYPES = new EnumArray(
    {
        key: 'ALL',
        label: i18n.t('ENUMS:SHIPPING_TYPES_ALL'),
    },
    {
        key: 'PRIME_ELIGIBLE',
        label: i18n.t('ENUMS:SHIPPING_TYPES_PRIME_ELIGIBLE'),
    },
    {
        key: 'NO_PRIME',
        label: i18n.t('ENUMS:SHIPPING_TYPES_NOT_PRIME_ELIGIBLE'),
    },
);

export const ACTUAL_ACOS_RANGES = new EnumArray(
    {
        key: 'DOWN',
        label: i18n.t('ENUMS:ACTUAL_ACOS_RANGES_FIRST'),
        item: {
            start: 0,
            end: 15,
        },
    },
    {
        key: 'NEUTRAL',
        label: i18n.t('ENUMS:ACTUAL_ACOS_RANGES_SECOND'),
        item: {
            start: 15,
            end: 30,
        },
    },
    {
        key: 'UP',
        label: i18n.t('ENUMS:ACTUAL_ACOS_RANGES_THIRD'),
        item: {
            start: 30,
            end: 100,
        },
    },
);

export const BUYBOX_STATUS = {
    BUYBOX_WINNING: 'WINNING',
    BUYBOX_CO_WINNING: 'CO_WINNING',
    BUYBOX_LOSING: 'LOSING',
    BUYBOX_NON_CATALOG: 'NON_CATALOG',
    BUYBOX_OUTSIDE_BUYBOX: 'OUTSIDE_BUYBOX',

};

export const RECOMMENDATIONS = {
    POUCOS_CLIQUES: 'nao-atrativo',
    POUCAS_IMPRESSOES: 'mude-a-estrategia',
    MUDE_ESTRATEGIA: 'mude-sua-estrategia',
    CPC_ALTO: 'cpc-alto',
    CONSUMINDO_MUITO_INVESTIMENTO: 'consumindo-muito-investimento',
    BAIXA_CONVERSAO: 'baixa-conversao',
    MELHORE_REPUTACAO: 'melhore-reputacao',
    MELHORE_SAUDE: 'melhore-saude',
    MELHORE_ORCAMENTO: 'melhore-orcamento',
    // AMAZON: 'amazon',
    MERCADO_LIVRE: 'mercado-livre',
    CAMPANHAS_ACABANDO: 'campanhas-acabando',
    ACOS_MAIOR_QUE_OBJETIVO: 'acos-maior-que-objetivo',
    // MELI: 'meli',
    PERDENDO_BUYBOX: 'perdendo-buybox',
    NEED_TO_IMPROVE: 'precisa-de-melhora',
    // READY_TO_INVEST: 'pronto-para-investir',
    // IN_LEARNING: 'em-aprendizado',
    GOOD_LISTING: 'oque-esta-bom',
    LISTING_WITHOUT_ADS: 'anuncios-sem-ads',
};

export const TYPES_RECOMMENDATIONS = new EnumArray(
    {
        key: '6a1b6e89-9f4d-4f8c-b56d-a60265d3a82f',
        param: RECOMMENDATIONS.POUCOS_CLIQUES,
    },
    {
        key: '92a03787-3b63-4b9a-8447-462c8ebef5b4',
        param: RECOMMENDATIONS.POUCAS_IMPRESSOES,
    },
    {
        key: 'ed320976-04be-4e8f-aec7-9f922d37a137',
        param: RECOMMENDATIONS.MUDE_ESTRATEGIA,
    },
    {
        key: 'a8e5ac4d-2e4f-427b-8c3a-91dfedbc3b1c',
        param: RECOMMENDATIONS.CPC_ALTO,
    },
    {
        key: 'e6f285cb-ea11-46d8-943d-49ee8b8d8e89',
        param: RECOMMENDATIONS.CONSUMINDO_MUITO_INVESTIMENTO,
    },
    {
        key: 'f2c08e56-1859-4b34-b88a-8499c9d9b914',
        param: RECOMMENDATIONS.BAIXA_CONVERSAO,
    },
    {
        key: 'dbd89b74-3fa5-42e3-9e7c-798a73e22a3c',
        param: RECOMMENDATIONS.CAMPANHAS_ACABANDO,
    },
    // {
    //     key: '8f0b7f3d-90b4-4f25-a1b2-0ed8b20bfe97',
    //     param: RECOMMENDATIONS.MELHORE_REPUTACAO,
    // },
    {
        key: 'c4655f80-7a9a-49c2-9fb5-68c9a96ac759',
        param: RECOMMENDATIONS.MELHORE_SAUDE,
    },
    // {
    //     key: 'd8e9a1b6-1b73-42df-9e6a-5f6d429f5b71',
    //     param: RECOMMENDATIONS.MELHORE_ORCAMENTO,
    // },
    {
        key: 'a40a02e8-22fe-43f9-9e4a-a48bf13a6f56',
        param: RECOMMENDATIONS.ACOS_MAIOR_QUE_OBJETIVO,
    },
    {
        key: '701c2a98-4d6b-4d87-916a-9c24e8cfb3c9',
        param: RECOMMENDATIONS.AMAZON,
    },
    {
        key: 'e7e0c3b5-8e5c-4d9d-9d88-6b8f6a42e72f',
        param: RECOMMENDATIONS.MELI,
    },
    {
        key: '532ca614-5225-4971-a532-8d393d0de951',
        param: RECOMMENDATIONS.PERDENDO_BUYBOX,
    },

    {
        key: '8064a35e-932c-4838-a5cc-1810cb9047c3',
        param: RECOMMENDATIONS.NEED_TO_IMPROVE,
    },
    // {
    //     key: '17f96ec5-b160-4e78-ab18-dbf9dbcd672d',
    //     param: RECOMMENDATIONS.READY_TO_INVEST,
    // },
    // {
    //     key: 'b0661c05-1093-4183-b948-0c87aeb9901e',
    //     param: RECOMMENDATIONS.IN_LEARNING,
    // },
    {
        key: 'a3f181a3-15c7-4aac-8939-9ba20162cf5c',
        param: RECOMMENDATIONS.LISTING_WITHOUT_ADS,
    },
    {
        key: '3b46d09d-4cfd-4fe7-ba53-8783bca134de',
        param: RECOMMENDATIONS.GOOD_LISTING,
    },
);

export const OPTIONS_GRAPHIC_HISTORY_ADS = new EnumArray(
    {
        key: 'IMPRESSIONS',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_HISTORY_ADS_IMPRESSIONS'),
        dataKey: 'impressions',
        color: '#ffd153',
        type: 'LINE',
        formatter: maskNumberWithoutDecimal,
    },
    {
        key: 'CLICKS',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_HISTORY_ADS_CLICKS'),
        dataKey: 'clicks',
        color: '#ff8c00',
        type: 'LINE',
        formatter: maskNumberWithoutDecimal,
    },
    {
        key: 'SALES',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_HISTORY_ADS_SALES'),
        dataKey: 'sales',
        color: '#3c3cff',
        type: 'LINE',
        formatter: maskNumberWithoutDecimal,
    },
    {
        key: 'CTR',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_HISTORY_ADS_CTR'),
        dataKey: 'ctr',
        color: '#4B0082',
        type: 'LINE',
        formatter: maskPercentage,
    },
    {
        key: 'CPC',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_HISTORY_ADS_CPC'),
        dataKey: 'cpc',
        color: '#03A9F4',
        type: 'LINE',
        formatter: maskMoney,
    },
    {
        key: 'ACOS',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_HISTORY_ADS_ACOS'),
        dataKey: 'acos',
        color: '#9C27B0',
        type: 'LINE',
        formatter: maskPercentage,
    },
    {
        key: 'ROAS',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_HISTORY_ADS_ROAS'),
        dataKey: 'roas',
        color: '#008080',
        type: 'LINE',
        formatter: maskRoas,
    },
);

export const OPTIONS_GRAPHIC_DASHBOARD = new EnumArray(
    {
        key: 'INVESTMENTS',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_DASHBOARD_INVESTMENTS'),
        dataKey: 'investments',
        color: '#F44336',
        type: 'LINE',
        formatter: maskMoney,
    },
    {
        key: 'REVENUE',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_DASHBOARD_REVENUE'),
        dataKey: 'revenue',
        color: '#32CD32',
        type: 'LINE',
        formatter: maskMoney,
    },
    {
        key: 'IMPRESSIONS',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_DASHBOARD_IMPRESSIONS'),
        dataKey: 'impressions',
        color: '#ffd153',
        type: 'LINE',
        formatter: maskNumberWithoutDecimal,
    },
    {
        key: 'CLICKS',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_DASHBOARD_CLICKS'),
        dataKey: 'clicks',
        color: '#ff8c00',
        type: 'LINE',
        formatter: maskNumberWithoutDecimal,
    },
    {
        key: 'CTR',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_DASHBOARD_CTR'),
        dataKey: 'ctr',
        color: '#4B0082',
        type: 'LINE',
        formatter: maskPercentage,
    },
    {
        key: 'CPC',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_DASHBOARD_CPC'),
        dataKey: 'cpc',
        color: '#03A9F4',
        type: 'LINE',
        formatter: maskMoney,
    },
    {
        key: 'ACOS',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_DASHBOARD_ACOS'),
        dataKey: 'acos',
        color: '#9C27B0',
        type: 'LINE',
        formatter: maskPercentage,
    },
    {
        key: 'ROAS',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_DASHBOARD_ROAS'),
        dataKey: 'roas',
        color: '#008080',
        type: 'LINE',
        formatter: maskRoas,
    },
    {
        key: 'ROI',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_DASHBOARD_ROI'),
        dataKey: 'roi',
        color: '#FF00FF',
        type: 'LINE',
        formatter: maskPercentage,
    },
    {
        key: 'CONVERSION_RATE',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_DASHBOARD_CONVERSION_RATE'),
        dataKey: 'conversionRate',
        color: '#800000',
        type: 'LINE',
        formatter: maskPercentage,
    },
    {
        key: 'ORGANIC_SALES',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_DASHBOARD_ORGANIC_SALES'),
        dataKey: 'organicSales',
        color: '#7383ff',
        type: 'BAR',
        formatter: maskNumberWithoutDecimal,
    },
    {
        key: 'SALES',
        label: i18n.t('ENUMS:OPTIONS_GRAPHIC_DASHBOARD_SALES_ADS'),
        dataKey: 'sales',
        color: '#3c3cff',
        type: 'BAR',
        formatter: maskNumberWithoutDecimal,
    },
);

export const BUYBOX_OPTIONS = new EnumArray(
    {
        key: 'WINNING',
        label: i18n.t('ENUMS:WINNING'),
    },
    {
        key: 'CO_WINNING',
        label: i18n.t('ENUMS:CO_WINNING'),
    },
    {
        key: 'LOSING',
        label: i18n.t('ENUMS:LOSING'),
    },
    {
        key: 'NON_CATALOG',
        label: i18n.t('ENUMS:NON_CATALOG'),
    },
    {
        key: 'OUTSIDE_BUYBOX',
        label: i18n.t('ENUMS:OUTSIDE_BUYBOX'),
    },
);

export const ADS_STATUS_OPTIONS = new EnumArray(
    {
        key: 'IN_CAMPAIGN',
        label: i18n.t('ENUMS:IN_CAMPAIGN'),
    },
    {
        key: 'NO_CAMPAIGN',
        label: i18n.t('ENUMS:NO_CAMPAIGN'),
    },
);

export const LANGUAGE_OPTIONS = new EnumArray(
    {
        key: 'pt',
        label: i18n.t('ENUMS:PORTUGUESE'),
    },
    {
        key: 'en',
        label: i18n.t('ENUMS:ENGLISH'),
    },
    {
        key: 'es',
        label: i18n.t('ENUMS:SPANISH'),
    },
);

export const STRATEGY_RULE_OPTIONS = new EnumArray(
    {
        key: 'WIN',
        label: i18n.t('ENUMS:NEW_RULE_RADIO_STRATEGY_WIN'),
    },
    {
        key: 'LOSE',
        label: i18n.t('ENUMS:NEW_RULE_RADIO_STRATEGY_LOSE'),
    },
    {
        key: 'MATCH',
        label: i18n.t('ENUMS:NEW_RULE_RADIO_STRATEGY_MATCH'),
    },
);

export const BASE_ON_OPTIONS = new EnumArray(
    {
        key: 'WINNER',
        label: i18n.t('ENUMS:NEW_RULE_RADIO_BASE_ON_WINNER'),
    },
    {
        key: 'LOWEST_PRICE',
        label: i18n.t('ENUMS:NEW_RULE_RADIO_BASE_ON_LOWEST_PRICE'),
    },
);

export const PRICE_ADJUSTMENT = new EnumArray(
    {
        key: 'PER_PERCENTAGE',
        label: i18n.t('ENUMS:NEW_RULE_RADIO_PRICE_ADJUSTMENT_PERCENTAGE'),
    },
    {
        key: 'PER_VALUE',
        label: i18n.t('ENUMS:NEW_RULE_RADIO_PRICE_ADJUSTMENT_VALUE'),
    },
);

export const TYPE_COMPARISON = new EnumArray(
    {
        key: 'IDENTICAL_ADVERTISEMENTS',
        label: i18n.t('ENUMS:NEW_RULE_RADIO_TYPE_COMPARISON_IDENTICAL'),
    },
    {
        key: 'SIMILAR_ADVERTISEMENTS',
        label: i18n.t('ENUMS:NEW_RULE_RADIO_TYPE_COMPARISON_SIMILAR'),
    },
);

export const COMPETITION_TYPE = new EnumArray(
    {
        key: 'BUYBOX',
        label: i18n.t('ENUMS:BUYBOX_COMPETITION_TYPE'),
    },
    {
        key: 'EXTERNAL',
        label: i18n.t('ENUMS:EXTERNAL_COMPETITION_TYPE'),
    },
    {
        key: 'ACOS',
        label: i18n.t('ENUMS:ACOS_COMPETITION_TYPE'),
    },
    {
        key: 'ROAS',
        label: i18n.t('ENUMS:ROAS_COMPETITION_TYPE'),
    },
);

export const LOCAL_OPERATOR_OPTIONS = new EnumArray(
    {
        key: 'AND',
        label: i18n.t('ENUMS:AND'),
    },
    {
        key: 'OR',
        label: i18n.t('ENUMS:OR'),
    },
);
